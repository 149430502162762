<template>
  <div class="tw-m-[20px_30px_0_30px] tw-p-[15px_15px_32px_15px] tw-bg-[#FFF] tw-rounded-[15px]">
    <Splide :options="sliderOptions">
      <SplideSlide v-for="(item, index) in items" :key="index">
        <div
          @click="navigate(item)"
          class="tw-flex tw-items-center max-md:tw-flex-col tw-gap-[8px] tw-p-[10px_18px] tw-border tw-border-solid tw-border-[#E7EFF3] tw-rounded-[12px] hover:tw-shadow-[0_6px_15px] hover:tw-shadow-[#C9C9C9]/20 tw-duration-300 tw-cursor-pointer"
        >
          <div class="tw-text-[#0D3659] tw-font-semibold tw-leading-none tw-text-nowrap">{{ item.title }}</div>
          <img :src="'/assets/images/start-new-order/' + item.image" :alt="item.title" class="tw-w-[124px] tw-min-w-[124px] tw-h-[40px] md:tw-h-[60px] tw-object-contain">
        </div>
      </SplideSlide>
    </Splide>
  </div>
</template>

<script>
import { BOOKING_URL } from '@/plugins/consts';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import AppRequestQuote from '@/components/Modals/AppRequestQuote.vue';

export default {
  data() {
    return {
      items: [
        {
          title: 'Apartment Moves',
          image: '1.svg',
          isHelpersOnly: false,
        },
        {
          title: 'Store Delivery',
          image: '2.svg',
          url: BOOKING_URL + '/delivery',
        },
        {
          title: 'Move a Few Items',
          image: '3.svg',
          url: BOOKING_URL + '/delivery',
        },
        {
          title: 'Labor Only',
          image: '4.svg',
          isHelpersOnly: true,
        },
        {
          title: 'Declutter Items',
          image: '5.svg',
          isHelpersOnly: true,
        },
        {
          title: 'Used Item Delivery',
          image: '6.svg',
          url: BOOKING_URL + '/delivery',
        },
        {
          title: 'Small Business Moves',
          image: '7.svg',
          isHelpersOnly: false,
        },
        {
          title: 'Storage Moves',
          image: '8.svg',
          isHelpersOnly: false,
        },
        {
          title: 'Junk Removal',
          image: '9.svg',
          isHelpersOnly: true,
        },
      ],
      sliderOptions: {
        type: 'slider',
        perPage: 5,
        perMove: 1,
        gap: 8,
        speed: 1000,
        arrows: false,
        pagination: true,
        autoWidth: true,
        autoplay: true,
      },
    }
  },

  components: {
    Splide,
    SplideSlide,
  },

  methods: {
    navigate(item) {
      if (item.url) {
        // Redirect
        window.location.href = item.url;
      } else {
        // Close current modal
        this.$emit('close');
        // Open request quote modal
        this.$modal.show(AppRequestQuote, {
          isHelpersOnly: item.isHelpersOnly
        }, {
          width: 945,
          height: 640,
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .splide__pagination {
    bottom: -23px;

    li {

      .splide__pagination__page {
        opacity: 1;
        border-radius: 4px;
        background-color: #0D3659;
                transition: all .3s ease-in-out;
             -o-transition: all .3s ease-in-out;
            -ms-transition: all .3s ease-in-out;
           -moz-transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;

        &.is-active {
          width: 15px;
          transform: scale(1);
          background-color: #8F3985;
        }
      }
    }
  }
}
</style>
