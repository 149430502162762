<template>
    <v-app class="p-sm-3 order-modal__wrapper" v-if="isLoaded">
        <div class="d-flex align-center justify-space-between w-full px-3 mb-3">
            <small></small>
            <v-btn text fab small class="mr-n3" @click="$emit('close')">
                <v-icon size="15">fa-times</v-icon>
            </v-btn>
        </div>
        <div class="order-modal" v-if="!isLoading">
            <div class="order-modal__header">
                {{ '#' + order.id + '-Order' }}
            </div>
            <div class="order-modal__body">
                <div class="order-modal__row">
                    <div class="d-flex py-2 row">
                        <div class="me-auto col-12 col-sm-auto" v-if="isNewOrder">
                            <v-btn
                                depressed
                                color="light"
                                class="custom--v-btn col-auto"
                                @click="reject(orderId)"
                            >
                                <v-icon size="15" class="mr-3">fa-times-circle</v-icon>
                                Reject
                            </v-btn>
                        </div>
                        <div class="me-auto col-12 col-sm-auto" v-if="isAcceptedOrder">
                            <v-btn
                                depressed
                                color="light"
                                class="custom--v-btn"
                                @click="reject_after_accepting(orderId)"
                            >
                                <v-icon size="15" class="mr-3">fa-times-circle</v-icon>
                                Cancel Job
                            </v-btn>
                        </div>
                        <div class="ms-sm-3 col-12 col-sm-auto"
                             v-if="isAcceptedOrder || isNewOrder"
                        >
                            <v-btn
                                depressed
                                color="secondary"
                                class="custom--v-btn"
                                @click="openRescheduleModal"
                            >
                                <v-icon size="15" class="mr-3">fa-clock</v-icon>
                                Reschedule
                            </v-btn>
                        </div>
                        <div class="ms-sm-3 col-12 col-sm-auto" v-if="isAcceptedOrder">
                            <v-btn
                                :disabled="!order.canComplete"
                                elevation="0"
                                color="light"
                                @click="complete(order.id)"
                            >
                                Complete
                            </v-btn>
                        </div>
                    </div>
                </div>
                <div class="order-modal__row">
                    <v-row class="order-modal__info">
                        <v-col class="col-auto" sm="12" md="6">
                            <h3 class="order-modal__title">Reservation</h3>
                            <ul>
                                <li>Date of move: <strong>{{ order.arrival_date }}</strong></li>
                                <li>Show-up time: <strong>{{ order.arrival_time }}</strong></li>
                                <li>Service method: <strong>{{ order.service }}</strong></li>
                                <li>Type of move: <strong>{{ order.type_moving }}</strong></li>
                                <li>Number of bedrooms: <strong>{{ order.bedrooms_count }}</strong></li>
                                <li>Number of floors: <strong>{{ order.stories_count }}</strong></li>
                            </ul>
                        </v-col>
                        <v-col class="col-auto" sm="12" md="6">
                            <h3 class="order-modal__title">Contact Info</h3>
                            <ul>
                                <li>Full name: <strong>{{ order.name }}</strong></li>
                                <li>Email: <a :href="`mailto:${order.email}`"><strong>{{ order.email }}</strong></a>
                                </li>
                                <li>
                                    Phone number: <br>
                                    <strong>
                                        {{ order.phone }}<br>
                                        {{ order.secondary_phone }}
                                    </strong>
                                </li>
                            </ul>
                        </v-col>
                        <v-col class="col-auto" sm="12" md="6" v-if="order.pickups.length > 0">
                            <h3 class="order-modal__title">Pickups</h3>
                            <ul v-for="{address, city_or_town, state, zip_code, type_property} of order.pickups"
                                :key="zip_code">
                                <li>Name of street & apt or suit #: <strong>{{ address + ' ' + city_or_town }}</strong>
                                </li>
                                <li>City name & State & Zip-code: <strong>{{ state + ' ' + zip_code }}</strong></li>
                                <li>Property Type: <strong>{{ property_types[type_property] }}</strong></li>
                            </ul>
                        </v-col>
                        <v-col class="col-auto" sm="12" md="6" v-if="order.destinations.length > 0">
                            <h3 class="order-modal__title">Destinations</h3>
                            <ul v-for="{address, city_or_town, state, zip_code, type_property} of order.destinations"
                                :key="zip_code">
                                <li>Name of street & apt or suit #: <strong>{{ address + ' ' + city_or_town }}</strong>
                                </li>
                                <li>City name & State & Zip-code: <strong>{{ state + ' ' + zip_code }}</strong></li>
                                <li>Property Type: <strong>{{ property_types[type_property] }}</strong></li>
                            </ul>
                        </v-col>
                        <v-col class="col-auto" sm="12" md="6">
                            <h3 class="order-modal__title">Order details</h3>
                            <ul>
                                <li>Number of helpers: <strong>{{ order.helpers_count }}</strong></li>
                                <li>Minimum hours: <strong>{{ order.hours_count }}</strong></li>
                                <li>Type of help: <strong>{{ order.type_help }}</strong></li>
                                <li>Transportation type: <strong>{{ order.type_transportation.join(', ') }}</strong>
                                </li>
                                <li v-if="order.truck_size">
                                    Size of truck: <strong>{{ order.truck_size }}</strong>
                                </li>
                                <li>Payment type: <strong>{{ order.type_payment }}</strong></li>
                                <li v-if="order.unique_items">
                                    Extra heavy items:
                                    <strong>{{ order.unique_items }}</strong>
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                </div>
                <div class="order-modal__row">
                    <h3 class="order-modal__title">
                        Invoice
                    </h3>
                    <v-data-table
                        :headers="headers"
                        :items="invoice"
                        hide-default-footer
                        class="custom--v-table"
                    />
                </div>
                <div v-if="isCustomer ? order.customerCanReview : order.helperCanReview"
                     class="order-modal__action d-flex align-center justify-center my-4">
                    <v-btn class="custom--v-btn" color="primary" @click="openReviewModal">
                        Set Review
                    </v-btn>
                </div>
            </div>
        </div>
    </v-app>

    <app-loading-indicator v-else/>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import loading from "../../mixins/loading";
import AppLoadingIndicator from "../AppLoadingIndicator";
import {ORDER_STATUSES, PROPERTY_TYPES} from "@/plugins/data";
import AppReschedule from "@/components/Modals/AppReschedule";
import {successConfirmAction, warningConfirmAction} from "@/plugins/swal";
import AppReview from "@/components/Modals/AppReview.vue";

export default {
    props: {
        orderId: {
            type: Number,
            required: true
        }
    },

    mixins: [loading],

    components: {
        AppLoadingIndicator,
    },

    data() {
        return {
            /** @type {Order|null} */
            order: null,

            headers: [
                {text: 'Item', sortable: false, value: 'item',},
                {text: 'Subtotal', sortable: false, value: 'subtotal'},
            ],

            property_types: PROPERTY_TYPES,
        };
    },

    methods: {
        ...mapActions('orders', ['show']),

        load() {
            this.startLoading(async () => {
                this.order = await this.show(this.orderId);
            });
        },

        openRescheduleModal() {
            this.$modal.show(AppReschedule, {
                order: this.order,
                afterClose: this.$attrs.afterClose,
            }, {
                width: 1000,
                height: 700,
            }, {
                closed: this.$attrs.afterClose
            });
        },

        reject(order_id) {
            warningConfirmAction('Do you really want to reject this order?', 'Reject it', async () => {
                await this.$store.dispatch('dashboard/reject', order_id);
                this.load();
            });
        },

        reject_after_accepting(order_id) {
            warningConfirmAction('Do you really want to reject accepted order?', 'Reject it', async () => {
                await this.$store.dispatch('dashboard/reject_after_accepting', order_id);
                this.load();
            });
        },

        complete(order_id) {
            successConfirmAction("Do you really want to complete this order?", 'Complete it', async () => {
                await this.$store.dispatch('dashboard/complete', order_id)
            });
        },

        openReviewModal() {
            this.$modal.show(AppReview, {
                order: this.order
            }, {
                width: 764,
                height: 700,
            }, {
                closed: this.load
            });
        },
    },

    computed: {
        ...mapGetters('auth', ['isCustomer']),

        isCompletedOrder() {
            return this.order.status_id === ORDER_STATUSES.STATUS_COMPLETED
        },

        isNewOrder() {
            return this.order.status_id === ORDER_STATUSES.STATUS_NEW;
        },

        isAcceptedOrder() {
            return this.order.status_id === ORDER_STATUSES.STATUS_ACCEPTED
        },

        invoice() {
            return [
                {
                    item: 'Hourly',
                    subtotal: `$${this.order.invoice.hourly}/hr (Cash)`
                },
                {
                    item: 'Minimum',
                    subtotal: `${this.order.hours_count}-hour`
                },
                {
                    item: 'Service Fee',
                    subtotal: `$${this.order.invoice.service_fee}`
                },
                {
                    item: 'Double Drive Time',
                    subtotal: this.order.invoice.is_double_drive ? 'Applies' : 'Does not apply'
                },
                {
                    item: `Total for ${this.order.hours_count}-hour minimum`,
                    subtotal: '$' + this.order.hours_count * this.order.invoice.hourly,
                },
                {
                    item: 'Top Helpers fee',
                    subtotal: '$' + (this.order.redeem_enabled || this.order.processing_fee === 0 ? this.order.invoice.hourly * 0.75 : this.order.processing_fee),
                },
                {
                    item: 'Total',
                    subtotal: '$' + this.order.invoice.total,
                }
            ]
        }
    },

    async created() {
        this.load();
    }
}
</script>

<style lang="scss" scoped>
.order-modal {
    margin: 0 12px 12px 12px;

    &__header {
        padding: 8px 15px;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        color: #FFF;
        background-color: #8F3985;
    }

    &__body {
        border: 1px solid #E0E4E9;
        border-top: 0;
    }

    &__row {
        padding: 15px;

        &:not(:last-child) {
            border-bottom: 1px solid #E0E4E9;
        }

        //    mobile

        @media (max-width: 599px) {
            .custom--v-btn::v-deep, button {
                width: 100%;
                margin-right: 0 !important;
                margin-left: 0 !important;
            }
        }
    }

    &__title {
        color: #0D3659;
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 10px;
    }

    &__info {

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                color: #0D3659;
                font-size: 14px;

                strong {
                    font-weight: 700;
                }
            }
        }
    }

    // Customize vuetify components
    ::v-deep {

        // Textarea
        .v-textarea {
            margin-bottom: 15px;

            fieldset {
                border: 0 !important;
            }

            textarea {
                padding: 0 10px;

                &::-webkit-input-placeholder {
                    font-size: 14px;
                }

                &:-ms-input-placeholder {
                    font-size: 14px;
                }

                &::placeholder {
                    font-size: 14px;
                }
            }
        }

        .custom--v-table {

            th, td {
                padding: 8px 20px;

                &:first-child {
                    white-space: nowrap;
                }

                &:last-child {
                    text-align: right !important;
                }
            }
        }
    }
}

//mobile order-modal
@media only screen and (max-width: 830px) {
    .order-modal__wrapper {
        max-width: 95%;
        margin: 0 auto;
    }
}
</style>
