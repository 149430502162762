<template>
    <v-main>
        <div class="login">
            <div class="login__left d-flex align-center">
                <div class="login__left-inner">
                    <div class="login__left-form">
                        <a :href="frontend_url" class="login__left-logo mb-7">
                            <img src="/assets/images/logos/logo-purple.svg" alt="logo"/>
                        </a>
                        <div class="mb-8 pb-6 border-b border-light border-solid d-block text-secondary">
                            <strong class="font-weight-bold">
                                Sign Up
                            </strong>
                        </div>
                        <div class="login__left-input">
                            <div class="d-flex align-center">
                                <div class="login__left-first_name">
                                    <div class="login__left__header mb-2.5 text-secondary font-medium">
                                        First name
                                    </div>
                                    <v-text-field
                                        v-model.trim="first_name"
                                        label="Your first name"
                                        single-line
                                        hide-details
                                        outlined
                                        type="text"
                                        class="custom--v-text-field"
                                        :error="$v.first_name.$error"
                                    />
                                    <small v-if="$v.first_name.$error" v-show="isClicked">
                                        Invalid first name
                                    </small>
                                </div>
                                <div class="login__left-last_name">
                                    <div class="login__left__header mb-2.5 text-secondary font-medium">
                                        Last name
                                    </div>
                                    <v-text-field
                                        single-line
                                        hide-details
                                        outlined
                                        label="Your last name"
                                        class="custom--v-text-field"
                                        :error="$v.last_name.$error"
                                        v-model.trim="last_name"
                                    />
                                    <small v-if="$v.last_name.$error" v-show="isClicked">
                                        Invalid last name
                                    </small>
                                </div>
                            </div>
                            <div>
                                <div class="login__left__header mb-2.5 text-secondary font-medium">
                                    Email
                                </div>
                                <v-text-field
                                    single-line
                                    hide-details
                                    outlined
                                    type="email"
                                    label="Write your email"
                                    class="custom--v-text-field"
                                    :error="$v.email.$error"
                                    v-model.trim="email"
                                />
                                <small v-if="$v.email.$error" v-show="isClicked">
                                    Invalid email
                                </small>

                            </div>
                            <div>
                                <div class="login__left__header mb-2.5 text-secondary font-medium">
                                    Password
                                </div>
                                <v-text-field
                                    single-line
                                    hide-details
                                    outlined
                                    class="custom--v-text-field"
                                    v-model.trim="password"
                                    label="Write your password"
                                    :append-icon="showCurrent ? 'fa-eye' : 'fa-eye-slash'"
                                    :type="showCurrent ? 'text' : 'password'"
                                    @click:append="showCurrent = !showCurrent"
                                    :error="$v.password.$error"
                                />
                                <small v-if="$v.password.$error" v-show="isClicked">
                                    Invalid password
                                </small>
                            </div>
                            <div class="login__left__referral">
                                <div class="login__left__header mb-2.5 text-secondary font-medium">
                                    Referral Code
                                </div>
                                <v-text-field
                                    single-line
                                    hide-details
                                    outlined
                                    type="text"
                                    class="custom--v-text-field"
                                    v-model.trim="referral_code"
                                />
                            </div>
                        </div>
                        <v-btn
                            depressed
                            block
                            x-large
                            color="primary"
                            class="custom--v-btn mb-5"
                            @click="save"
                            :loading="isLoading"
                        >
                            Register now
                        </v-btn>
                        <div class="text-center">
                            <v-btn
                                depressed
                                block
                                x-large
                                color="light"
                                class="custom--v-btn mt-1"
                                @click.prevent="signin"
                             >
                            Sign in
                        </v-btn>
                    </div>
                    </div>
                </div>
            </div>
            <div class="login__right"/>
        </div>
    </v-main>
</template>
<script>
import loading from "@/mixins/loading.js";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { DASHBOARD_URL, WP_URL } from "@/plugins/consts.js";


export default {
    head() {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: DASHBOARD_URL + '/sign-up',
                },
            ],
        };
    },

    name: "ReferralSignUp",
    mixins: [loading, validationMixin],

    data () {
        return {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            referral_code: '',
            frontend_url: WP_URL,
            showCurrent: false,
            isClicked: false,
        }
    },

    validations: {
        first_name: {
            required
        },
        last_name: {
            required
        },
        email: {
            required,
            email
        },
        password: {
            required,
            minLength: minLength(8)
        }
    },
    methods: {
        ...mapActions('auth', ['register']),

        async save () {
            // Validate form
            this.isClicked = true;
            this.$v.$touch();

            // If form is invalid, return
            if (this.$v.$invalid) {
                return false;
            }

            let user = {
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                password: this.password,
                referral_code: this.referral_code
            }

            try {
                await this.register(user);
                return this.$router.push({ name: 'login' });
            } catch (e) {
                console.error(e);
            }
        },

        signin() {
            this.$router.push('/login');
        }
    },

    created () {
        if (window.location.href.includes('referral_code')) {
            this.referral_code = window.location.href.split('referral_code=')[1];
        }
    }
}
</script>

<style scoped lang="scss">
small {
    color: #ff5252;
    position: absolute;
    bottom: -20px;
}

::v-deep {
    .custom--v-text-field {
        .v-input__slot {
            height: 51px !important;
        }

        .error--text {
            fieldset {
                border-color: #ff5252 !important;
            }
        }
    }

    .login__left__referral {
        .custom--v-text-field {
            .v-input__slot {
                background: #ddd;
            }

            input {
                font-size: 16px;
                color: #0D3659;
                font-weight: 500
            }
        }
    }
}
</style>
