import googleOneTap from "google-one-tap";
import { useRedirectUrl } from "@/router/routes.js";
import { GOOGLE_AUTH_CLIENT_ID } from '@/plugins/consts';
import tokenize from '@/plugins/tokenize';
import oAuthService from "@/services/auth/oAuthService.js";

export default {
  mounted () {
    if (tokenize.get()) return;

    const options = {
      client_id: GOOGLE_AUTH_CLIENT_ID,
      auto_select: false,
      cancel_on_tap_outside: false,
      context: 'signin',
    };

    googleOneTap(options, async (response) => {
      const { user, token } = await oAuthService.googleLogin(response.credential);

      // Save token and set headers
      tokenize.save(token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + tokenize.get();

      // Set user and auth status
      this.$store.commit("auth/SET_USER", { user, isAuthenticated: true });

      // Redirect to dashboard
      if (!useRedirectUrl()) {
        await this.$router.push({ name: "dashboard" });
      }
    });
  },
}
