import { DOMAIN } from "@/plugins/consts";

export default {
    token: 'mover_app_token',

    get () {
        return $cookies.get(this.token);
    },

    save (token) {
        $cookies.set(this.token, token, 60 * 60 * 24 * 7, '/', DOMAIN)

        return localStorage.setItem(this.token, token);
    },

    delete () {
        $cookies.remove(this.token, '/', DOMAIN);

        return localStorage.removeItem(this.token);
    }
}
