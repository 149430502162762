<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-p-[50px]">
<!--    <pre class="tw-w-full tw-mb-[40px]">{{ form }}</pre>-->
    <div class="tw-flex tw-items-center tw-gap-[15px]">
      <i @click="goBack()" class="icony icony-arrow-left-circle tw-text-[#AEB5C4] !tw-w-[30px] tw-duration-300 hover:tw-text-[#0D3659] tw-cursor-pointer"></i>
      <h3 class="tw-text-[#0D3659] tw-font-semibold tw-text-[25px] md:tw-text-[35px] tw-leading-none">Service needed</h3>
    </div>
    <div class="tw-mt-[40px] max-sm:tw-w-full">
      <div class="tw-flex max-sm:tw-flex-col tw-gap-[8px]">
        <button
          v-for="(item, index) in typeService"
          :key="index"
          class="tw-px-[10px] tw-py-[12px] tw-bg-[#F8F9FD] tw-font-medium tw-text-[#0D3659] tw-leading-none tw-rounded-[5px] tw-duration-300 hover:tw-bg-[#0D3659] hover:tw-text-[#FFF]"
          :class="{'!tw-bg-[#0D3659] !tw-text-[#FFF]': form.type_service === index + 1}"
          @click="onChangeTypeService(item.value)"
        >
          {{ item.text }}
        </button>
      </div>
    </div>
    <div class="tw-mt-[25px] max-sm:tw-w-full" v-if="form.type_service === 1">
      <div class="tw-flex max-sm:tw-flex-col tw-gap-[5px] tw-p-[5px] tw-bg-[#F5F7FF] tw-rounded-[10px]">
        <button
          v-for="(item, index) in typeHelp"
          :key="index"
          class="tw-px-[10px] tw-py-[11px] tw-bg-[#F5F7FF] tw-font-medium tw-text-[#AEB5C4] tw-leading-none tw-border tw-border-solid tw-border-[#FFF] tw-rounded-[10px] tw-duration-300 hover:tw-bg-[#FFF] hover:tw-text-[#0D3659]"
          :class="{'!tw-bg-[#FFF] !tw-text-[#0D3659]': form.type_help === index + 1}"
          @click="onChangeTypeHelp(item.value)"
        >
          {{ item.text }}
        </button>
      </div>
    </div>
    <div class="tw-mt-[50px] tw-w-full">
      <div class="tw-grid sm:tw-grid-cols-2 tw-gap-[25px]">
        <div v-if="form.type_help !== 2 || form.type_service === 2">
          <div class="tw-text-[18px] tw-font-medium tw-text-[#6B6D81] tw-leading-none tw-mb-[10px]">Loading zip code</div>
          <div class="tw-relative">
            <input v-model="form.from_zip" v-maska="zip_mask" type="text" placeholder="Zip code" class="tw-w-full tw-pl-[50px] tw-pr-[15px] tw-h-[50px] tw-bg-[#F5F7FF] tw-text-[#0D3659] tw-text-[18px] tw-font-medium tw-outline-none tw-rounded-[10px] placeholder:tw-text-[#AEB5C4]">
            <div class="tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-left-0 tw-w-[50px] tw-aspect-square tw-pointer-events-none">
              <i class="icony icony-truck-mapmarker tw-text-[#AEB5C4] !tw-w-[52%]"></i>
            </div>
          </div>
          <span class="tw-text-[red] tw-text-[14px]" v-if="form.from_zip && $v.form.from_zip.$invalid">Invalid zip code</span>
        </div>
        <div v-if="form.type_help !== 2 || form.type_service === 2">
          <div class="tw-text-[18px] tw-font-medium tw-text-[#6B6D81] tw-leading-none tw-mb-[10px]">Loading date</div>
          <v-menu v-model="fromDate">
            <template v-slot:activator="{ on, attrs }">
              <div class="tw-relative">
                <input v-model="form.from_date" readonly v-bind="attrs" v-on="on" type="text" placeholder="Choose date" class="tw-w-full tw-pl-[50px] tw-pr-[15px] tw-h-[50px] tw-bg-[#F5F7FF] !tw-text-[#0D3659] tw-text-[18px] tw-font-medium tw-outline-none tw-rounded-[10px] placeholder:tw-text-[#AEB5C4]">
                <div class="tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-left-0 tw-w-[50px] tw-aspect-square tw-pointer-events-none">
                  <i class="icony icony-truck-mapmarker tw-text-[#AEB5C4] !tw-w-[52%]"></i>
                </div>
              </div>
            </template>
            <v-date-picker v-model="form.from_date" @input="fromDate = false"></v-date-picker>
          </v-menu>
          <span class="tw-text-[red] tw-text-[14px]" v-if="form.from_date && $v.form.from_date.$invalid">Date is required</span>
        </div>
        <div v-if="form.type_help !== 1 || form.type_service === 2">
          <div class="tw-text-[18px] tw-font-medium tw-text-[#6B6D81] tw-leading-none tw-mb-[10px]">Unloading zip code</div>
          <div class="tw-relative">
            <input v-model="form.to_zip" v-maska="zip_mask" type="text" placeholder="Zip code" class="tw-w-full tw-pl-[50px] tw-pr-[15px] tw-h-[50px] tw-bg-[#F5F7FF] tw-text-[#0D3659] tw-text-[18px] tw-font-medium tw-outline-none tw-rounded-[10px] placeholder:tw-text-[#AEB5C4]">
            <div class="tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-left-0 tw-w-[50px] tw-aspect-square tw-pointer-events-none">
              <i class="icony icony-truck-mapmarker tw-text-[#AEB5C4] !tw-w-[52%]"></i>
            </div>
          </div>
          <span class="tw-text-[red] tw-text-[14px]" v-if="form.to_zip && $v.form.to_zip.$invalid">Invalid zip code</span>
        </div>
        <div v-if="form.type_help !== 1 || form.type_service === 2">
          <div class="tw-text-[18px] tw-font-medium tw-text-[#6B6D81] tw-leading-none tw-mb-[10px]">Unloading date</div>
          <v-menu v-model="toDate">
            <template v-slot:activator="{ on, attrs }">
              <div class="tw-relative">
                <input v-model="form.to_date" readonly v-bind="attrs" v-on="on" type="text" placeholder="Choose date" class="tw-w-full tw-pl-[50px] tw-pr-[15px] tw-h-[50px] tw-bg-[#F5F7FF] !tw-text-[#0D3659] tw-text-[18px] tw-font-medium tw-outline-none tw-rounded-[10px] placeholder:tw-text-[#AEB5C4]">
                <div class="tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-left-0 tw-w-[50px] tw-aspect-square tw-pointer-events-none">
                  <i class="icony icony-truck-mapmarker tw-text-[#AEB5C4] !tw-w-[52%]"></i>
                </div>
              </div>
            </template>
            <v-date-picker v-model="form.to_date" @input="toDate = false"></v-date-picker>
          </v-menu>
          <span class="tw-text-[red] tw-text-[14px]" v-if="form.to_date && $v.form.to_date.$invalid">Date is required</span>
        </div>
        <div>
          <div class="tw-text-[18px] tw-font-medium tw-text-[#6B6D81] tw-leading-none tw-mb-[10px]">Type of moving</div>
          <div class="tw-relative">
            <v-select
              :items="typeMoving"
              v-model="form.type_moving"
              item-text="text"
              item-value="value"
              placeholder="Choose one"
              hide-details
              append-icon=""
              solo
              class="v-select"
            ></v-select>
            <div class="tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-left-0 tw-w-[50px] tw-aspect-square tw-pointer-events-none">
              <i class="icony icony-home tw-text-[#AEB5C4] !tw-w-[38%]"></i>
            </div>
          </div>
        </div>
        <div>
          <div class="tw-text-[18px] tw-font-medium tw-text-[#6B6D81] tw-leading-none tw-mb-[10px]">How many bedrooms?</div>
          <div class="tw-relative">
            <v-select
              :items="bedroomsCount"
              v-model="form.bedrooms_count"
              item-text="text"
              item-value="value"
              placeholder="Choose one"
              hide-details
              append-icon=""
              solo
              class="v-select"
            ></v-select>
            <div class="tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-left-0 tw-w-[50px] tw-aspect-square tw-pointer-events-none">
              <i class="icony icony-bed tw-text-[#AEB5C4] !tw-w-[38%]"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-mt-[50px]">
      <div class="tw-w-[300px]">
        <button @click="submit()" class="tw-w-full tw-bg-[#8F3985] tw-p-[11.5px_15px] tw-text-[18px] tw-font-bold tw-text-[#FFF] tw-rounded-[8px] hover:tw-bg-[#0D3659] tw-duration-300">Submit now</button>
      </div>
    </div>
  </div>
</template>

<script>
import AppCustomerStartNewOrder from '@/components/Modals/AppCustomerStartNewOrder.vue';
import { zip_mask } from "@/plugins/custom_masks";
import { maska } from "maska";
import { BOOKING_URL, BACKEND_URL } from '@/plugins/consts';
import { mapActions } from 'vuex';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';

export default {
  props: {
    isHelpersOnly: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      typeService: [
        { text: "Helpers Only", value: 1 },
        { text: "Helpers and Trucks", value: 2 }
      ],
      typeHelp: [
        { text: 'Loading only', value: 1 },
        { text: 'Unloading only', value: 2 },
        { text: 'Loading & Unloading', value: 3 }
      ],
      typeMoving: [
        { text: 'Residential', value: 1 },
        { text: 'Commercial', value: 2 }
      ],
      bedroomsCount: [
        { text: 'Single/Studio', value: -1 },
        { text: '1 bedroom', value: 1 },
        { text: '2 bedrooms', value: 2 },
        { text: '3 bedrooms', value: 3 },
        { text: '4 bedrooms', value: 4 },
        { text: '5 bedrooms', value: 5 },
        { text: '6 bedrooms', value: 6 },
        { text: '7 bedrooms', value: 7 },
        { text: '8 bedrooms', value: 8 },
        { text: '9 bedrooms', value: 9 },
        { text: '10 bedrooms', value: 10 }
      ],
      fromDate: false,
      toDate: false,
      zip_mask,

      // Form data
      form: {
        type_service: this.isHelpersOnly ? 1 : 2,
        type_help: 1,
        from_zip: '',
        from_date: null,
        to_zip: '',
        to_date: null,
        type_moving: null,
        bedrooms_count: null,
        helpers_count: null,
      },
    }
  },

  methods: {
    ...mapActions('customer_new_order', ['validateZipCode']),

    goBack() {
      // Close current modal
      this.$emit('close');
      // Open request quote modal
      this.$modal.show(AppCustomerStartNewOrder, {}, {
        width: 740,
        height: 630,
      });
    },

    onChangeTypeService(value) {
      this.form.type_service = value;
    },

    onChangeTypeHelp(value) {
      this.form.type_help = value;
    },

    async validateZipCode(zip_code) {
      if (zip_code.length === 5) {
        return await axios.get('zip_code/' + zip_code, {
          baseURL: BACKEND_URL + '/validations/'
        })
      }
    },

    submit() {
      const formData = { ...this.form };

      if (formData.type_service === 2) formData.type_help = 3;

      if (formData.type_help === 1) {
        delete formData.to_zip;
        delete formData.to_date;
      } else if (formData.type_help === 2) {
        delete formData.from_zip;
        delete formData.from_date;
      }

      if (formData.bedrooms_count === -1) formData.bedrooms_count = 1;
      formData.helpers_count = formData.bedrooms_count + 1;
      if (formData.helpers_count > 10) formData.helpers_count = 10;

      const queryString = new URLSearchParams(formData).toString();
      window.location.href = `${ BOOKING_URL }/helper-list?${ queryString }`;
    }
  },

  directives: {
    maska
  },

  validations: {
    form: {
      from_zip: {
        required,
        minLength: minLength(5),
      },
      from_date: {
        required,
      },
      to_zip: {
        required,
        minLength: minLength(5),
      },
      to_date: {
        required,
      },
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {

  .v-select {
    margin-top: 0;
    padding-top: 0;

    .v-input__slot {
      padding-left: 50px !important;
      box-shadow: none !important;
      background-color: #f5f7ff !important;

      .v-select__selection {
        color: #0D3659;
        font-weight: 500;
        font-size: 18px;
      }

      input::placeholder {
        font-size: 18px;
        color: #AEB5C4;
        font-weight: 500;
      }
    }
  }
}
</style>
