/**
 * Services
 */
export const MOVERS_ONLY = 1;
export const MOVERS_AND_TRUCKS = 2;

/**
 * Trucks sizes
 */
export const TRUCK_SIZE_SMALLEST = "12-14";
export const TRUCK_SIZE_SMALLER = "15-17";
export const TRUCK_SIZE_MEDIUM = "18-20";
export const TRUCK_SIZE_BIGGER = "21-23";
export const TRUCK_SIZE_BIGGEST = "24-26";

export const TRUCK_SIZES = [
    // https://mover.atlassian.net/browse/MAT-503
    //
    // TRUCK_SIZE_SMALLEST,
    // TRUCK_SIZE_SMALLER,
    // TRUCK_SIZE_MEDIUM,
    // TRUCK_SIZE_BIGGER,
    TRUCK_SIZE_BIGGEST
];

/**
 * Google keys
 */
export const GOOGLE_AUTH_CLIENT_ID = process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID || applicationConfiguration.GOOGLE_AUTH_CLIENT_ID;

/**
 * Base URL
 */
export const BACKEND_URL = process.env.VUE_APP_BACKEND_URL || applicationConfiguration.BACKEND_URL;
export const WP_URL = process.env.VUE_APP_WP_URL || applicationConfiguration.WP_URL;
export const BOOKING_URL = process.env.VUE_APP_BOOKING_URL || applicationConfiguration.BOOKING_URL;
export const DASHBOARD_URL = process.env.VUE_APP_DASHBOARD_URL || applicationConfiguration.DASHBOARD_URL;
export const DOMAIN = process.env.VUE_APP_DOMAIN || applicationConfiguration.DOMAIN;
