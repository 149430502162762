<template>
    <div>
        <h3 class="tw-text-[20px] tw-font-semibold tw-text-[#0D3659] tw-mb-[14px]">Your Referral Code</h3>

        <div class="tw-flex tw-relative">
            <div class="tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-left-0 tw-h-full tw-aspect-square tw-pointer-events-none">
                <i class="icony icony-share tw-w-[14px] tw-text-[#AEB5C4]"></i>
            </div>
            <input
                type="text"
                v-model="referral.code"
                @input="check"
                v-maska="'XXXXXXX'"
                placeholder="Type your referral code"
                :class="{ 'tw-border-[#FE6D60]': !isValid }"
                class="tw-w-full tw-h-[50px] tw-px-[45px] tw-border tw-border-solid tw-border-[#DDDDDD] tw-rounded-[8px] !tw-text-[#0D3659] tw-font-semibold tw-tracking-[5px] placeholder:!tw-[#AEB5C4] placeholder:tw-tracking-normal"
            />
            <div class="tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-right-[95px] tw-h-full tw-aspect-square tw-pointer-events-none" v-if="!isValid">
              <i class="icony icony-info-circle-o !tw-w-[40%] tw-text-[#FE6D60]"></i>
            </div>
            <v-menu>
                <template v-slot:activator="{ attrs, on }">
                    <v-btn
                        v-bind="attrs" v-on="on"
                        depressed
                        :disabled="!isValid || !referral.code"
                        class="!tw-h-[calc(100%_-_2px)] !tw-px-[24px] tw-absolute tw-top-[1px] tw-right-[1px] !tw-rounded-l-none !tw-rounded-r-[8px] [&.theme--light.v-btn.v-btn--disabled.v-btn--has-bg]:!tw-bg-[#F5F5F9] !tw-bg-[#F5F5F9] !tw-text-[#0D3659] disabled:!tw-text-[#AEB5C4] !tw-text-[16px]"
                    >Copy</v-btn>
                </template>

                <v-list class="custom--v-list" outlined>
                    <v-list-item
                        v-for="(item, index) in items"
                        :key="item"
                        active-class="referral-code__selected-items py-0"
                        link
                        @click="copy(index, referral.moverLink, referral.clientLink)"
                    >
                        <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { copyClipboard } from "@/plugins/helpers.js";
import { sweetAlertError } from "@/plugins/swal.js";
import { maska } from "maska";
import { DASHBOARD_URL, WP_URL } from '@/plugins/consts';

export default {
    name: "InviteFriendsGenerateLink",

    data () {
        return {
            items: [
                'Refer Movers',
                'Refer Clients',
                'Code Only'
            ],
            oldReferralCode: '',
            isValid: true,
        }
    },

    computed: {
      ...mapState('referrals', ['referralData']),

      referral () {
        return {
          code: this.referralData.referral_code,
          moverLink: WP_URL + '/register?referral_code=',
          clientLink: DASHBOARD_URL + '/sign-up?referral_code=',
        }
      }
    },

    methods: {
        ...mapActions('auth', ['updateCustomer']),

        copy(index, moverLink, clientLink) {
            if(this.referral.code) {
                if(index === 0) copyClipboard(moverLink + this.referral.code, 'Invite link is copied successfully for Movers', 'Error while is copying');
                if(index === 1) copyClipboard( clientLink + this.referral.code, 'Invite link is copied successfully for Clients', 'Error while is copying');
                return copyClipboard(this.referral.code, 'Referral code is copied successfully');
            }
            sweetAlertError("Referral code doesn't exist");
        },

        check() {
          if (this.referral.code.length === 7 ) {
            this.isValid = true;

            // Update
            this.updateCustomerReferralCode();
            this.oldReferralCode = this.referral.code;
          } else {
            this.isValid = false;
          }
        },

        updateCustomerReferralCode() {
          if (this.referral.code !== this.oldReferralCode) {
            this.updateCustomer({
              referral_code: this.referral.code
            });
          }
        }
    },

    directives: {
      maska
    },
}
</script>
