<template>
    <div class="invite-link tw-grid lg:tw-grid-cols-2 2xl:tw-grid-cols-9 tw-gap-[16px] tw-mb-[16px]">
        <InviteFriendsGenerateLink class="2xl:tw-col-span-4"/>
        <InviteFriendsShareSocialLinks class="2xl:tw-col-span-3" :referral="referral"/>
    </div>
</template>

<script>
import InviteFriendsGenerateLink from "./InviteFriendsGenerateLink.vue";
import { mapState } from "vuex";
import { DASHBOARD_URL, WP_URL } from "../../../plugins/consts.js";
import InviteFriendsShareSocialLinks from "./InviteFriendsShareSocialLinks.vue";

export default {
    name: "InviteFriendsHeader",

    components: { InviteFriendsShareSocialLinks, InviteFriendsGenerateLink },

    data () {
        return {
            moverLink: WP_URL + '/register?referral_code=',
            clientLink: DASHBOARD_URL + '/sign-up?referral_code=',
        }
    },

    computed: {
        ...mapState('referrals', ['referralData']),

        referral () {
            return {
                code: this.referralData.referral_code,
                moverLink: this.moverLink,
                clientLink: this.clientLink
            }
        }
    },
}
</script>
