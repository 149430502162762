import { BACKEND_URL } from "@/plugins/consts.js";

export default {
  googleLogin: async (token) => {
    return await axios.post('/google/auth/login-via-token', {
      token: token
    }, {
      baseURL: BACKEND_URL
    });
  },
}
